import MobileHeader from '@src/components/MobileHeader';
import PlayLayout from '@src/components/PlayLayout';
import CloseIcon from '@src/assets/icons/close-icn.svg';
import { styled } from 'stitches.config';
import React, { ChangeEvent } from 'react';
import { navigate } from 'gatsby';
import { usePlayroomContext } from '@src/providers/Playroom';
import { usePostModifyRoomMutation } from '@src/apis/mutation';
import { useGetRoomQuery } from '@src/apis/queries';
import { useToastContext } from '@src/providers/Toast';

export default function CreateNotice({ params }: { params: { id: string } }) {
  const { id } = params;

  const { openSheet } = usePlayroomContext();
  const { showToast } = useToastContext();

  const [notice, setNotice] = React.useState('');
  const disabled = notice.length === 0;

  const { mutateAsync: createNotice } = usePostModifyRoomMutation();
  const { data: roomData } = useGetRoomQuery(id, true);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotice(e.target.value);
  };

  const handleCloseClick = () => {
    navigate(-1);

    openSheet();
  };

  const handleSubmit = React.useCallback(async () => {
    if (!roomData?.data) return;
    if (!notice) return;

    const { data } = await createNotice({
      roomId: id,
      infoObject: {
        title: roomData?.data?.title,
        description: notice,
        maxUserCount: roomData?.data?.maxUserCount,
        status: roomData?.data?.status,
        password: roomData?.data?.password,
      },
    });

    if (data) {
      navigate(-1);

      openSheet();

      showToast('공지가 등록되었어요.');
    }
  }, [roomData, notice]);

  return (
    <PlayLayout>
      <MobileHeader
        left={
          <Button onClick={handleCloseClick}>
            <img src={CloseIcon} alt="닫기 아이콘" />
          </Button>
        }
        center={<Title>플레이룸 공지 쓰기</Title>}
        right={
          <Button
            onClick={handleSubmit}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          >
            <SubmitText disabled={disabled}>완료</SubmitText>
          </Button>
        }
      />
      <TextArea
        value={notice}
        onChange={handleChange}
        placeholder="참여자들에게 전하고 싶은 말을 적어주세요!&#13;&#10;플레이룸 규칙, 게임 설명, 각 플레이어 역할 등을 작성해주시면 좋아요."
      />
    </PlayLayout>
  );
}

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

const Title = styled('h1', {
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '22px',
  color: '#191919',
});

const SubmitText = styled('span', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',

  variants: {
    disabled: {
      true: {
        color: '#d6d6d8',
      },
      false: {
        color: '#c869ff',
      },
    },
  },
});

const TextArea = styled('textarea', {
  width: '100%',
  height: '100%',
  padding: '16px',
  border: 'none',
  color: '#191919',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  resize: 'none',

  '&:focus': {
    // border: '2px solid #c869ff',
    outline: '#c869ff auto 5px',
  },
});
