import { styled } from 'stitches.config';

export default function PlayLayout({ children }: React.PropsWithChildren) {
  return (
    <Container>
      <PlayArea>{children}</PlayArea>
    </Container>
  );
}

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const PlayArea = styled('div', {
  position: 'relative',
  width: '100%',
  maxWidth: '500px',
  height: '100%',
  maxHeight: '1104px',
  margin: '0 auto',
});
