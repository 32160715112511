import { styled } from 'stitches.config';

interface MobileHeaderProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  customStyles?: React.CSSProperties;
}

export default function MobileHeader({
  left,
  center,
  right,
  customStyles,
}: MobileHeaderProps) {
  return (
    <Container style={{ ...customStyles }}>
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>{right}</Right>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '48px',
});

const Left = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: '8px',
});

const Center = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
});

const Right = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: '8px',
});
